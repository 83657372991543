/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 25, 2021 */

$font-path: '../assets/fonts/' !default;

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-black-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-black-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-black-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-black-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-black-webfont.svg#brandon_textblack') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-thin-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-thin-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-thin-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-thin-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-thin-webfont.svg#brandon_textthin') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-light-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-light-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-light-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-light-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-light-webfont.svg#brandon_textlight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-medium-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-medium-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-medium-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-medium-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-medium-webfont.svg#brandon_textmedium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-regular-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-regular-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-regular-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-regular-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-regular-webfont.svg#brandon_textregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Italics */

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-lightitalic-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-lightitalic-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-lightitalic-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-lightitalic-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-lightitalic-webfont.svg#brandon_textlight_italic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-regularitalic-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-regularitalic-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-regularitalic-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-regularitalic-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-regularitalic-webfont.svg#brandon_textregular_italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-bolditalic-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-bolditalic-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-bolditalic-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-bolditalic-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-bolditalic-webfont.svg#brandon_textbold_italic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-blackitalic-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-blackitalic-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-blackitalic-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-blackitalic-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-blackitalic-webfont.svg#brandon_textblack_italic') format('svg');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-mediumitalic-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-mediumitalic-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-mediumitalic-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-mediumitalic-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-mediumitalic-webfont.svg#brandon_textmedium_italic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'brandon_text';
  src: url('#{$font-path}/brandon_text/brandontext-thinitalic-webfont.eot');
  src:
    url('#{$font-path}/brandon_text/brandontext-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/brandon_text/brandontext-thinitalic-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-thinitalic-webfont.woff') format('woff'),
    url('#{$font-path}/brandon_text/brandontext-thinitalic-webfont.ttf') format('truetype'),
    url('#{$font-path}/brandon_text/brandontext-thinitalic-webfont.svg#brandon_textthin_italic') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'brandon_text';
  src:
    url('#{$font-path}/brandon_text/brandontext-bold-webfont.woff2') format('woff2'),
    url('#{$font-path}/brandon_text/brandontext-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
